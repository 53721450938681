import React, { FC, useMemo } from 'react';
import { ModuleContainer } from '~/templates/blocks/components/ModuleContainer';
import { PLPHero } from './components/PLPHero/PLPHero';
import ProductsLoader from './ProductsLoader';
import { TPLPModule } from './PLPModule.definitions';
import { generateAlgoliaConfigProps } from './Algoliga.utils';
import { InstantSearch, InstantSearchSSRProvider } from 'react-instantsearch';
import { algoliaClient, MainAlgoliaIndex } from '~/lib/algolia';
import { routing as generateRouting } from './routing';
import PLPSchema from './components/PLPSchema';

const PLPModule: FC<TPLPModule> = ({
    heroHeader,
    heroTheme,
    categories,
    image,
    heroImageAltText,
    backgroundColor,
    hitsPerPage,
    filters,
    parentDataConfiguration,
    parentDataType,
    dataType,
    children,
    serverState,
    query,
}) => {
    const algoliaProps = useMemo(
        () =>
            generateAlgoliaConfigProps({
                filter: filters,
                filterType: dataType,
                productIds: query?.productids ?? query?.productIds,
                campaign: query?.campaign,
                parentFilter: parentDataConfiguration,
                parentFilterType: parentDataType,
            }),
        [dataType, filters, query, parentDataConfiguration, parentDataType],
    );

    const routing = useMemo(() => {
        if (!serverState?.serverUrl) return undefined;

        return generateRouting(serverState?.serverUrl);
    }, [serverState?.serverUrl]);

    return (
        <InstantSearchSSRProvider {...serverState?.serverState}>
            <InstantSearch
                indexName={MainAlgoliaIndex}
                searchClient={algoliaClient}
                routing={routing}
            >
                <PLPSchema />
                <ModuleContainer spacingTop="none">
                    {!!heroHeader && (
                        <PLPHero
                            heroHeader={heroHeader}
                            image={image}
                            heroImageAltText={heroImageAltText}
                            heroTheme={heroTheme}
                            backgroundColor={backgroundColor}
                            dataType={dataType}
                            categories={categories}
                        />
                    )}
                    {children}
                    <ProductsLoader
                        identifier="Product Listing"
                        hitsPerPage={hitsPerPage}
                        filters={filters}
                        configureProps={algoliaProps}
                        serverUrl={serverState?.serverUrl}
                        query={query}
                    />
                </ModuleContainer>
            </InstantSearch>
        </InstantSearchSSRProvider>
    );
};

export default PLPModule;
